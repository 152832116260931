<!-- @Author: wuhuiwang -->
<!-- @Date: 2023-11-15 14:50:27 -->
<!-- @Last Modified by: lijiahang -->
<!-- @Last Modified time: 2023-11-28 14:25:25 -->

<template>
    <div class="blog">
        <div class="container">
            <div
                v-reach-show="'elegant-in'"
                class="blog-ctn"
                @click="handleToBlog"
            >
                <div class="blog-bg">
                    <img
                        class="bg-ctn"
                        src="./img/bwc.png"
                    >
                </div>
                <div class="blog-list">
                    <div class="blog-logo">
                        Blog
                    </div>
                    <swiper
                        ref="mySwiper"
                        class="blog-swiper"
                        :options="swiperOptions"
                    >
                        <swiper-slide
                            v-for="(item, index) in $t('borrow.blog.list')"
                            :key="index"
                        >
                            <div class="slide-box">
                                <div class="title">
                                    {{ item.title }}
                                </div>
                                <div class="date">
                                    {{ item.date }}
                                </div>
                                <div class="desc">
                                    {{ item.desc }}
                                </div>
                            </div>
                        </swiper-slide>
                        <div
                            slot="pagination"
                            class="swiper-pagination"
                        />
                    </swiper>
                    <img
                        class="jump"
                        src="./img/jump.png"
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import 'swiper/css/swiper.css';
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';

export default {
    name: 'Blog',

    components: {
        Swiper,
        SwiperSlide
    },

    data() {
        return {
            swiperOptions: {
                speed: 500,
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: false
                },
                loop: true,
                pagination: {
                    el: '.swiper-pagination'
                }
            }
        };
    },

    methods: {
        handleToBlog() {
            this.$router.push({name: 'Blog'});
        }
    }
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.blog {
    padding: 100px 0;
    font-family: Lexend;

    @media screen and (max-width: $screen-md) {
        padding: 40px 0;
    }

    &-ctn {
        width: 100%;
        padding: 60px;
        border-radius: 40px;
        background: $black-98-l;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;
        cursor: pointer;

        @media screen and (max-width: $screen-md) {
            padding: 0;
            border-radius: 20px;
            flex-direction: column;
        }

        .blog-bg {
            width: 600px;
            height: 400px;
            border-radius: 40px;
            margin-right: 60px;
            flex-shrink: 0;
            background-image: url("./img/bg.png");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            overflow: hidden;
            position: relative;

            .bg-ctn {
                width: 344px;
                height: 181px;
                position: absolute;
                left: 32px;
                bottom: 32px;
            }

            @media screen and (max-width: $screen-md) {
                width: 100%;
                height: 200px;
                border-radius: 20px 20px 0 0;
                margin-right: 0;
                margin-bottom: 20px;

                .bg-ctn {
                    width: 160px;
                    height: 86px;
                    position: absolute;
                    left: 16px;
                    bottom: 16px;
                }
            }
        }

        .blog-list {
            flex: 1;
            width: calc(100% - 720px);

            @media screen and (max-width: $screen-md) {
                width: 100%;
            }

            .blog-logo {
                width: 82px;
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #1e1e1e;
                border-radius: 8px;
                color: #d0d5cf;
                font-family: Fugaz One;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 145%;
                margin-bottom: 20px;

                @media screen and (max-width: $screen-md) {
                    display: none;
                }
            }

            .blog-swiper {
                width: 100%;
                position: unset;

                @media screen and (max-width: $screen-md) {
                    padding-bottom: 68px;
                }

                ::v-deep .swiper-slide {
                    width: 100% !important;
                    box-sizing: border-box;

                    @media screen and (max-width: $screen-md) {
                        padding: 0 16px;
                    }
                }

                ::v-deep .swiper-pagination {
                    width: fit-content;
                    left: 720px;
                    bottom: 72px;

                    @media screen and (max-width: $screen-md) {
                        left: 16px;
                        bottom: 32px;
                    }

                    .swiper-pagination-bullet {
                        width: 8px;
                        height: 8px;
                        background: #c0c0ca;
                        border-radius: 4px;
                        margin: 0 6px;

                        @media screen and (max-width: $screen-md) {
                            width: 4px;
                            height: 4px;
                            border-radius: 2px;
                            margin: 0 4px;
                        }
                    }

                    .swiper-pagination-bullet-active {
                        background: #1e1e1e;
                    }
                }
            }

            .slide-box {
                width: 100%;

                .title {
                    color: $base-black;
                    font-size: 32px;
                    font-weight: 700;
                    line-height: 130%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                }

                .date {
                    margin: 12px 0;
                    color: $black-60-l;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 160%;
                }

                .desc {
                    color: $black-45-l;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 160%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                }

                @media screen and (max-width: $screen-md) {
                    .title {
                        font-size: 16px;
                        line-height: 160%;
                        overflow: unset;
                    }

                    .date {
                        margin: 4px 0;
                        font-size: 12px;
                        line-height: 145%;
                    }

                    .desc {
                        font-size: 14px;
                        -webkit-line-clamp: 4;
                    }
                }
            }

            .jump {
                width: 48px;
                height: 48px;
                position: absolute;
                right: 60px;
                bottom: 72px;
                cursor: pointer;

                @media screen and (max-width: $screen-md) {
                    width: 36px;
                    height: 36px;
                    right: 16px;
                    bottom: 16px;
                }
            }
        }
    }
}
</style>
